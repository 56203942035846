import appPackageInfo from "../../package.json"
import { LOCAL_STORAGE, POSSIBLY_TABLET_RESOLUTION_IN_PX } from "./constants"

export const getSongPath = (songName: string) => `${process.env.PUBLIC_URL}/audio/${songName}.mp3`

export function shuffleArray<T>(array: T[], optionalIndexToOmit?: number): T[] {
    let currentIndex = array.length, randomIndex

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        if (randomIndex === optionalIndexToOmit || currentIndex === optionalIndexToOmit) {
            continue
        }
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

export const isProbablyMobile = (): boolean =>
    (('ontouchstart' in window) || navigator.maxTouchPoints > 0) && window.screen.width < POSSIBLY_TABLET_RESOLUTION_IN_PX

export const getIsNewVersionArrivedAndUpdateStorage = (): boolean => {
    const currentVersion = window.localStorage.getItem(LOCAL_STORAGE.version)
    const lastDotIndex = appPackageInfo.version.lastIndexOf(".")
    const newVersion = appPackageInfo.version.split('').filter((_, index) => index !== lastDotIndex).join('')
    window.localStorage.setItem(LOCAL_STORAGE.version, newVersion)
    if (!currentVersion) {
        return false
    }
    return Number(newVersion) > Number(currentVersion)
}

export const isTodayWithinRangeDate = (startDayAndMonth: [number, number], endDayAndMonth: [number, number]): boolean => {
    const today = new Date()
    today.setHours(0,0,0,0)
    const isEndDateSameAsStartDate = endDayAndMonth[0] === startDayAndMonth[0] && endDayAndMonth[1] === startDayAndMonth[1]
    const startDate = new Date(today.getFullYear(), startDayAndMonth[1] - 1, startDayAndMonth[0])
    if (isEndDateSameAsStartDate) {
        return today.valueOf() === startDate.valueOf()
    }
    const isEndDateInNextYear = endDayAndMonth[1] < startDayAndMonth[1]
    const endDate = new Date(isEndDateInNextYear ? today.getFullYear() + 1 : today.getFullYear(), endDayAndMonth[1] - 1, endDayAndMonth[0])
    return (today.valueOf() >= startDate.valueOf()) && (today.valueOf() <= endDate.valueOf())
}
