import { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { INITIAL_EXPLORATION_SONG_INDEX, SONG_NAMES_EXPLORATION } from 'utils/constants'

interface MediaSessionInput {
    isMusicModeChanging: boolean
    audioRef: RefObject<HTMLAudioElement>
    handlePlaySong: () => unknown
    handlePauseSong: () => unknown
    handleNextSong: () => unknown
    handlePreviousSong: () => unknown
}

function useMediaSession({
    isMusicModeChanging,
    audioRef,
    handlePlaySong,
    handlePauseSong,
    handleNextSong,
    handlePreviousSong
}: MediaSessionInput) {
  const { t } = useTranslation()
    const [currentSongMetaData, setCurrentSongMetaData] = useState<{ title: string; coverPartialUri: string }>({
        coverPartialUri: `${SONG_NAMES_EXPLORATION[INITIAL_EXPLORATION_SONG_INDEX].cover}`,
        title: `song.exploration.${SONG_NAMES_EXPLORATION[INITIAL_EXPLORATION_SONG_INDEX].title}`
    })

    useEffect(() => {
        if ("mediaSession" in navigator === false) {
            return
        }
        navigator.mediaSession.metadata = new MediaMetadata({
            title: t(currentSongMetaData.title),
            artist: t("component.mediasession.artist"),
            album: t("component.mediasession.album"),
            artwork: [{
                sizes: "256x256",
                src: `/assets/mediasession-covers/${currentSongMetaData.coverPartialUri}.jpg`,
                type: "image/jpeg"
            }]
        })
    }, [t, currentSongMetaData])

    useEffect(() => {
        const refCurrent = audioRef.current
        if (!refCurrent) {
            return
        }

        const updatePositionState = () => {
            if ("setPositionState" in navigator.mediaSession === false) {
                return
            }
            navigator.mediaSession.setPositionState({
                duration: refCurrent.duration,
                position: refCurrent.currentTime
            })
        }

        const actionHandlers: [MediaSessionAction, MediaSessionActionHandler][] = [
            ["play", () => {
                if (!isMusicModeChanging) {
                    handlePlaySong()
                    updatePositionState()
                }
            }],
            ["pause", () => {
                if (!isMusicModeChanging) {
                    handlePauseSong()
                }
            }],
            ["nexttrack", handleNextSong],
            ["previoustrack", handlePreviousSong],
            ["seekto", ({ seekTime }) => {
                if (seekTime) {
                    refCurrent.currentTime = seekTime
                    updatePositionState()
                }
            }]
        ]

        for (const [action, handler] of actionHandlers) {
            try {
              navigator.mediaSession.setActionHandler(action, handler)
            } catch (error) {}
        }
        const onPlayHandler = () => navigator.mediaSession.playbackState = "playing"
        const onPauseHandler = () => navigator.mediaSession.playbackState = "paused"
        refCurrent.addEventListener("play", onPlayHandler)
        refCurrent.addEventListener("pause", onPauseHandler)
        return () => {
            refCurrent.removeEventListener("play", onPlayHandler)
            refCurrent.removeEventListener("pause", onPauseHandler)
        }
    }, [audioRef, isMusicModeChanging, handlePlaySong, handlePauseSong, handleNextSong, handlePreviousSong])

    return setCurrentSongMetaData
}

export default useMediaSession