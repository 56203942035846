import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AUDIO_CLICK_EFFECT_ID } from "utils/constants"
import styles from "./Checkbox.module.css"

interface CheckboxProps {
    label: string
    id: string
    checked?: boolean
    onChange?: () => unknown
}

function Checkbox({ id, label, onChange, checked }: CheckboxProps) {
    const { t } = useTranslation()
    const audio = document.getElementById(AUDIO_CLICK_EFFECT_ID) as HTMLAudioElement
    const [hasFocus, setHasFocus] = useState<boolean>(false)

    useEffect(() => {
        if (!hasFocus) {
            return
        }
        const handleCheck = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                audio.play()
                onChange?.()
            }
        }
        document.addEventListener("keypress", handleCheck, { once: true })
        return () => {
          document.removeEventListener("keypress", handleCheck)
        }
    }, [hasFocus, onChange, audio])

    return (
        <label
            htmlFor={id}
            className={`${styles.root} ${checked && styles.checked} ${hasFocus && styles.focused}`}
            title={t("component.checkbox.select")}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
        >
            <input
                type="checkbox"
                id={id}
                onClick={() => audio.play()}
                onChange={() => onChange?.()}
                checked={checked}
            />
            <div className={styles.decoration} />
            <span>{label}</span>
        </label>
    )
}

export default Checkbox