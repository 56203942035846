import { useTranslation } from "react-i18next"
import Dialog from "../Dialog"

interface PrivacyPolicyDialogProps {
    onAccept: () => unknown
}

function PrivacyPolicyDialog({ onAccept }: PrivacyPolicyDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            textCenter
            title={t("dialog.privacy-policy.title")}
            onAccept={onAccept}
            onExit={onAccept}
        >
            <section className="privacyPolicyDialog">
                {t("dialog.privacy-policy.content")}
            </section>
        </Dialog>
    )
}

export default PrivacyPolicyDialog