import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { Button } from "components"
import styles from "./Dialog.module.css"
import { useLayoutEffect } from "react"

interface DialogProps {
    children: string | JSX.Element | JSX.Element[]
    title?: string
    onAccept?: () => void
    onDeny?: () => void
    onExit?: () => void
    isShown: boolean
    textCenter?: boolean
    size?: 'SMALL' | 'NORMAL'
}

const dialogRootElement = document.getElementById('dialog-root')!

function Dialog({ title, children, textCenter, size, isShown, onAccept, onDeny, onExit }: DialogProps) {
    const { t } = useTranslation()

    useLayoutEffect(() => {
        if (isShown) {
            document.getElementsByTagName("html")[0].classList.add("withDialog")
          } else {
            document.getElementsByTagName("html")[0].classList.remove("withDialog")
          }
    }, [isShown])

    if (!isShown) {
        return null
    }

    return createPortal(
        <section
            className={`${styles.root} ${size === 'SMALL' ? styles.small : ''} ${textCenter ? styles.textCenter : ''}`}
        >
            <section className={styles.content}>
                {title && <header className={styles.header}><h2>{title}</h2></header>}
                {onExit && (
                    <Button
                        onClick={() => onExit?.()}
                        className={styles.exitButton}
                        type="SQUARE"
                        title={t("component.dialog.exit")}
                    >
                        <img src={`${process.env.PUBLIC_URL}/assets/check.png`} alt="" />
                    </Button>
                )}
                <section className={styles.main}>
                    {children}
                </section>
                {(onAccept || onDeny) && (
                    <footer className={styles.footer}>
                        {onAccept && (
                            <Button
                                onClick={event => { event.preventDefault(); onAccept(); }}
                                type="ACCEPT"
                                title={t("component.dialog.accept")}
                            />
                        )}
                        {onDeny && (
                            <Button
                                onClick={event => { event.preventDefault(); onDeny(); }}
                                type="DENY"
                                title={t("component.dialog.deny")}
                            />
                        )}
                    </footer>
                )}
            </section>
        </section>,
        dialogRootElement
    )
}

export default Dialog