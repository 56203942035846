import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Mode,
  DB,
  SONG_NAMES_EXPLORATION,
  SONG_NAMES_COMBAT,
  LOCAL_STORAGE,
  TRANSITION_EFFECTS_TO_EXPLORATION,
  TRANSITION_EFFECTS_TO_COMBAT,
  ALL_SONGS,
  MANUAL_SCREEN_DELAY_IN_MS,
  INITIAL_EXPLORATION_SONG_INDEX,
  NOTIFICATION_PLAYER_SHOWN_IN_MS,
  TIP_SHOWN_IN_MS,
  INITIAL_SCREEN_DELAY_IN_MS,
  AudioResource
} from 'utils/constants'
import {
  clearResourcesInObjectStoreDatabase,
  countResourcesInDatabase,
  createInitialDataInDbAndGetStatus,
  getListOfResourcesSavedInDatabase,
  saveSingleResourceToIndexedDb,
  saveSingleResourceToSpecificDatabase
} from 'utils/indexedDb'
import { getSongPath } from 'utils/utils'
import { useMediaSession, useOnlineStatus, usePlaylistManager, usePrevious } from 'hooks'
import { Button, Dialog, Notification } from 'components'
import {
  CopyrightsDialog,
  SettingsDialog,
  ManualDialog,
  PrivacyPolicyDialog,
  SettingsDialogButton,
  ChangelogDialog,
  SupportDialog
} from 'components/dialog'
import { TimeEventContext } from 'utils/context'

import styles from "./Player.module.css"

function Player() {
  const { t } = useTranslation()
  const isOnline = useOnlineStatus()
  const currentTimeEvent = useContext(TimeEventContext);

  const [timeEventsNotification, setTimeEventsNotification] = useState<string>('')
  const [isDatabaseFeatureAvailable, setIsDatabaseFeatureAvailable] = useState<boolean>(false)
  const [areResourcesInitiallyCounted, setAreResourcesInitiallyCounted] = useState<boolean>(false)
  const [songsDownloaded, setSongsDownloaded] = useState<number>(0)
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState<boolean>(false)
  const [isManualDialogOpen, setIsManualDialogOpen] = useState<boolean>(false)
  const [shouldChangeModeTipBeShown, setShouldChangeModeTipBeShown] = useState<boolean>(false)
  const [isChangeModeTipShown, setIsChangeModeTipShown] = useState<boolean>(false)
  const [isChangelogDialogOpen, setIsChangelogDialogOpen] = useState<boolean>(false)
  const [isCopyrightsDialogOpen, setIsCopyrightsDialogOpen] = useState<boolean>(false)
  const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState<boolean>(false)
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState<boolean>(false)
  const [isRandomMusicNotificationShown, setIsRandomMusicNotificationShown] = useState<boolean>(false)
  const [isRepeatMusicNotificationShown, setIsRepeatMusicNotificationShown] = useState<boolean>(false)
  const [isDownloadFinished, setIsDownloadFinished] = useState<boolean>(true)
  const [hasDownloadPermission, setHasDownloadPermission] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE.database.hasDownloadPermission) === "true"
  )
  const [currentSongNameTranslationKey, setCurrentSongNameTranslationKey] = useState<string>("view.player.title-empty")
  const [currentSongCoverUri, setCurrentSongCoverUri] = useState<string>(`${process.env.PUBLIC_URL}/assets/albums/${SONG_NAMES_EXPLORATION[INITIAL_EXPLORATION_SONG_INDEX].cover}.jpg`)
  const previousHasDownloadPermission = usePrevious<boolean>(hasDownloadPermission)

  const [areTransitionEffectsActive, setAreTransitionEffectsActive] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive) === "true"
  )

  useEffect(() => {
    if (isSettingsDialogOpen) {
      return
    }
    setIsManualDialogOpen(false)
    setIsCopyrightsDialogOpen(false)
    setIsPrivacyPolicyDialogOpen(false)
  }, [isSettingsDialogOpen])

  useEffect(() => {
    const wasTimeEventsNotificationAlreadyShown = window.localStorage.getItem(LOCAL_STORAGE.miscellaneous.timeEvents.notification) === "true"
    if (
      !currentTimeEvent.current ||
      wasTimeEventsNotificationAlreadyShown ||
      isChangeModeTipShown ||
      shouldChangeModeTipBeShown
    ) {
      return;
    }
    const timeout = setTimeout(() => {
      setTimeEventsNotification(`component.notification.time-events.${currentTimeEvent.current!.toString().toLowerCase()}`)
    }, MANUAL_SCREEN_DELAY_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [currentTimeEvent, shouldChangeModeTipBeShown, isChangeModeTipShown])

  useEffect(() => {
    if (timeEventsNotification.length === 0) {
      return
    }
    const timeout = setTimeout(() => {
        setTimeEventsNotification('')
        window.localStorage.setItem(LOCAL_STORAGE.miscellaneous.timeEvents.notification, true.toString())
    }, TIP_SHOWN_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [timeEventsNotification])
  
  const audioRef = useRef<HTMLAudioElement>(null)
  const abortControllerRef = useRef<AbortController>(new AbortController())

  const {
    playNextSong,
    playPreviousSong,
    musicMode,
    toggleMusicMode,
    currentCombatSongIndex,
    currentExplorationSongIndex,
    isMusicRandomized,
    toggleRandomMode,
    isMusicRepeated,
    toggleRepeatMode
  } = usePlaylistManager()

  const [currentMusicMode, setCurrentMusicMode] = useState<Mode>(musicMode)
  const [isMusicModeChanging, setIsMusicModeChanging] = useState<boolean>(false)
  const [isMusicPlaying, setIsMusicPlaying] = useState<boolean>(true)
  
  useEffect(() => {
    createInitialDataInDbAndGetStatus()
      .then(() => setIsDatabaseFeatureAvailable(true))
      .catch(() => setIsDatabaseFeatureAvailable(false))

    const wasManualAlreadyIntroduced = window.localStorage.getItem(LOCAL_STORAGE.initial.wasManualDialogShown) === "true"
    if (wasManualAlreadyIntroduced) {
      return
    }
    setShouldChangeModeTipBeShown(true)
    const timeout = setTimeout(() => {
        setIsManualDialogOpen(true)
    }, MANUAL_SCREEN_DELAY_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive, areTransitionEffectsActive.toString())
  }, [areTransitionEffectsActive])

  const getResourceFromInternetAndSaveToDatabaseIfNeeded = useCallback((songPartialUri: string, signal?: AbortSignal): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (!isOnline) {
        return reject()
      }
      return fetch(getSongPath(songPartialUri), { signal })
        .then(response => response.blob())
        .then(blob => {
          resolve(blob)
          return blob.arrayBuffer()
        })
        .then(arrayBuffer => {
          if (!isDatabaseFeatureAvailable || !hasDownloadPermission) {
            return
          }
          return saveSingleResourceToIndexedDb(songPartialUri, arrayBuffer)
        })
        .then(countResources)
        .catch(reject)
    })
  }, [isDatabaseFeatureAvailable, hasDownloadPermission, isOnline])

  const getResourceFromDatabaseIfAvailable = useCallback((songPartialUri: string, signal?: AbortSignal): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (!isDatabaseFeatureAvailable) {
        return getResourceFromInternetAndSaveToDatabaseIfNeeded(songPartialUri, signal)
          .then(blob => resolve(blob))
          .catch(reject)
      }
      const request = window.indexedDB.open(DB.name)
      request.addEventListener("success", (event) => {
        const db: IDBDatabase = (event.target as IDBRequest).result
        const transaction = db.transaction(DB.objectStoreName, "readonly")
        const songRequest = transaction.objectStore(DB.objectStoreName).get(songPartialUri)
        songRequest.addEventListener("success", (event) => {
          const data: ArrayBuffer = (event.target as IDBRequest).result
          if (!data) {
            return getResourceFromInternetAndSaveToDatabaseIfNeeded(songPartialUri, signal)
              .then(blob => resolve(blob))
              .catch(reject)
          }
          const blob = new Blob([data], { type: "audio/mp3" })
          return resolve(blob)
        })
        songRequest.addEventListener("error", () => {
          getResourceFromInternetAndSaveToDatabaseIfNeeded(songPartialUri, signal)
            .then(blob => resolve(blob))
            .catch(reject)
        })
      })
      request.addEventListener("error", () => {
        getResourceFromInternetAndSaveToDatabaseIfNeeded(songPartialUri, signal)
          .then(blob => resolve(blob))
          .catch(reject)
      })
      request.addEventListener("blocked", () => {
        getResourceFromInternetAndSaveToDatabaseIfNeeded(songPartialUri, signal)
          .then(blob => resolve(blob))
          .catch(reject)
      })
    })
  }, [isDatabaseFeatureAvailable, getResourceFromInternetAndSaveToDatabaseIfNeeded])

  useEffect(() => {
    const refCurrent = audioRef.current
    if (!refCurrent || isMusicModeChanging) {
      return
    }
    const handleAudioPlay = () => {
      refCurrent.currentTime = 0
      setIsMusicPlaying(true)
      if (isMusicRepeated) {
        refCurrent.play()
      } else {
        playNextSong()
      }
    }
    refCurrent.addEventListener("ended", handleAudioPlay)
    return () => {
      refCurrent.removeEventListener("ended", handleAudioPlay)
    }
  }, [isMusicRepeated, isMusicModeChanging, playNextSong])

  const countResources = (): void => {
    countResourcesInDatabase().then(savedSongs => {
      setSongsDownloaded(savedSongs)
      setIsDownloadFinished(savedSongs === ALL_SONGS.length)
    })
  }

  const handlePauseSong = () => {
    audioRef.current!.pause()
    setIsMusicPlaying(false)
  }

  const handlePlaySong = () => {
    audioRef.current!.play()
    setIsMusicPlaying(true)
  }

  const toggleSongPlayingState = () => {
    if (!isMusicPlaying) {
      audioRef.current!.play()
    } else {
      audioRef.current!.pause()
    }
    setIsMusicPlaying(prevState => !prevState)
  }

  const handleNextSong = () => {
    if (isMusicModeChanging) {
      return
    }
    playNextSong()
  }

  const handlePreviousSong = () => {
    if (isMusicModeChanging) {
      return
    }
    playPreviousSong()
  }

  const handleRandomMode = () => {
    setIsRandomMusicNotificationShown(true)
    toggleRandomMode()
  }

  const handleRepeatMode = () => {
    setIsRepeatMusicNotificationShown(true)
    toggleRepeatMode()
  }

  const setCurrentSongMetaData = useMediaSession({
    audioRef,
    isMusicModeChanging,
    handlePlaySong,
    handlePauseSong,
    handleNextSong,
    handlePreviousSong
  })

  useEffect(() => {
    if (!hasDownloadPermission || !isDatabaseFeatureAvailable || isDownloadFinished) {
      return
    }
    getListOfResourcesSavedInDatabase().then((savedSongs: IDBValidKey[]) => {
      const songsToDownload = ALL_SONGS.filter(songName => !savedSongs.includes(songName.path))
      const request = window.indexedDB.open(DB.name)
      request.addEventListener("success", (event) => {
        songsToDownload.forEach((songPartialUri) => {
          const database: IDBDatabase = (event.target as IDBRequest).result

          fetch(getSongPath(songPartialUri.path))
            .then(response => response.blob())
            .then(blob => blob.arrayBuffer())
            .then((arrayBuffer) => {
              saveSingleResourceToSpecificDatabase(database, songPartialUri.path, arrayBuffer)
                .then(() => {
                  countResourcesInDatabase().then(savedSongs => {
                    setSongsDownloaded(savedSongs)
                    setIsDownloadFinished(savedSongs === ALL_SONGS.length)
                  })
                })
            })
        })
      })
    })
  }, [hasDownloadPermission, isDatabaseFeatureAvailable, isDownloadFinished])

  useEffect(() => {
    setCurrentMusicMode(musicMode)
  }, [musicMode])

  useEffect(() => {
    if (previousHasDownloadPermission && !hasDownloadPermission) {
      clearResourcesInObjectStoreDatabase().then(() => {
        setSongsDownloaded(0)
        setIsDownloadFinished(false)
      })
    }
    window.localStorage.setItem(LOCAL_STORAGE.database.hasDownloadPermission, hasDownloadPermission.toString())
  }, [previousHasDownloadPermission, hasDownloadPermission])

  useEffect(() => {
    if (!hasDownloadPermission || !isDatabaseFeatureAvailable) {
      setAreResourcesInitiallyCounted(true)
      return
    }
    countResourcesInDatabase().then(savedSongs => {
      setAreResourcesInitiallyCounted(true)
      setSongsDownloaded(savedSongs)
      setIsDownloadFinished(savedSongs === ALL_SONGS.length)
    })
  }, [hasDownloadPermission, isDatabaseFeatureAvailable])

  const songPartialUri = useMemo<AudioResource>(() => (musicMode === Mode.EXPLORATION ? SONG_NAMES_EXPLORATION : SONG_NAMES_COMBAT)[
    musicMode === Mode.EXPLORATION ? currentExplorationSongIndex : currentCombatSongIndex
  ], [currentCombatSongIndex, currentExplorationSongIndex, musicMode])

  useEffect(() => {
    const title = `song.${musicMode.toLowerCase()}.${songPartialUri.title}`
    setCurrentSongCoverUri(`/assets/albums/${songPartialUri.cover}.jpg`)
    setCurrentSongNameTranslationKey(title)
    setCurrentSongMetaData({
      title,
      coverPartialUri: songPartialUri.cover
    })
  }, [songPartialUri, musicMode, setCurrentSongMetaData])

  useEffect(() => {
    abortControllerRef?.current?.abort()
    abortControllerRef.current = new AbortController()
    if (isMusicModeChanging) {
      return
    }
    getResourceFromDatabaseIfAvailable(songPartialUri.path, abortControllerRef.current?.signal)
      .then(blob => audioRef.current!.src = URL.createObjectURL(blob))
      .then(() => {
        audioRef.current!.load()
        audioRef.current!.oncanplaythrough = () => {
          if (isMusicPlaying) {
            audioRef.current!.play()
          }
        }
      })
      .catch(() => {})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMusicModeChanging,
    musicMode,
    currentExplorationSongIndex,
    currentCombatSongIndex,
    songPartialUri.path,
    setCurrentSongMetaData,
    getResourceFromDatabaseIfAvailable,
  ])

  const handleMusicMode = () => {
    toggleMusicMode()
    if (isMusicModeChanging) {
      return
    }
    if (!areTransitionEffectsActive) {
      return;
    }
    const songUri = musicMode === Mode.COMBAT ?
      TRANSITION_EFFECTS_TO_EXPLORATION[Math.floor(Math.random() * TRANSITION_EFFECTS_TO_EXPLORATION.length)].path :
      TRANSITION_EFFECTS_TO_COMBAT[Math.floor(Math.random() * TRANSITION_EFFECTS_TO_COMBAT.length)].path
    setIsMusicModeChanging(true)
    getResourceFromDatabaseIfAvailable(songUri)
      .then(blob => audioRef.current!.src = URL.createObjectURL(blob))
      .then(() => {
        audioRef.current!.play()
        audioRef.current!.addEventListener("ended", () => {
          setIsMusicModeChanging(false)
        }, { once: true })
      })
      .catch(() => {
        setIsMusicModeChanging(false)
      })
  }

  useLayoutEffect(() => {
    if (isMusicModeChanging) {
      return
    }
    if (musicMode === Mode.COMBAT) {
      document.getElementsByTagName("html")[0].classList.add("withCombatWallpaper")
      document.getElementsByTagName("html")[0].classList.remove("withExplorationWallpaper")
    } else {
      document.getElementsByTagName("html")[0].classList.add("withExplorationWallpaper")
      document.getElementsByTagName("html")[0].classList.remove("withCombatWallpaper")
    }
  }, [ isMusicModeChanging, musicMode ])

  useLayoutEffect(() => {
    if (isMusicModeChanging) {
      document.getElementsByTagName("html")[0].classList.add("loading")
    } else {
      document.getElementsByTagName("html")[0].classList.remove("loading")
    }
  }, [isMusicModeChanging])

  useEffect(() => {
    if (!isRandomMusicNotificationShown) {
      return
    }
    const timeout = setTimeout(() => {
        setIsRandomMusicNotificationShown(false)
    }, NOTIFICATION_PLAYER_SHOWN_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [isRandomMusicNotificationShown])

  useEffect(() => {
    if (!isRepeatMusicNotificationShown) {
      return
    }
    const timeout = setTimeout(() => {
        setIsRepeatMusicNotificationShown(false)
    }, NOTIFICATION_PLAYER_SHOWN_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [isRepeatMusicNotificationShown])

  useEffect(() => {
    if (!isChangeModeTipShown || !shouldChangeModeTipBeShown) {
      return
    }
    const timeout = setTimeout(() => {
        setIsChangeModeTipShown(false)
    }, TIP_SHOWN_IN_MS)
    return () => {
        clearTimeout(timeout)
    }
  }, [shouldChangeModeTipBeShown, isChangeModeTipShown])

  return (
    <>
      {shouldChangeModeTipBeShown && isChangeModeTipShown && (
        <>
          <Notification>{t("view.mode.tip")}</Notification>
          <div className={styles.tip}>
            <img src={`${process.env.PUBLIC_URL}/assets/manual/arrow.png`} alt="" />
            <img src={`${process.env.PUBLIC_URL}/assets/manual/arrow.png`} alt="" />
            <img src={`${process.env.PUBLIC_URL}/assets/manual/arrow.png`} alt="" />
            <img src={`${process.env.PUBLIC_URL}/assets/manual/target.png`} alt="" />
          </div>
        </>
      )}
      <div className="specialModeChristmasSnow"></div>
      <section className={styles.root}>
        {isSettingsDialogOpen && (
          <SettingsDialog
            songsDownloaded={songsDownloaded}
            hasDownloadPermission={hasDownloadPermission}
            areTransitionEffectsActive={areTransitionEffectsActive}
            onClose={() => setIsSettingsDialogOpen(false)}
            onManualDialogOpen={() => setIsManualDialogOpen(true)}
            onChangelogDialogOpen={() => setIsChangelogDialogOpen(true)}
            onCopyrightsDialogOpen={() => setIsCopyrightsDialogOpen(true)}
            onPrivacyPolicyDialogOpen={() => setIsPrivacyPolicyDialogOpen(true)}
            onSupportDialogOpen={() => setIsSupportDialogOpen(true)}
            setAreTransitionEffectsActive={() => setAreTransitionEffectsActive(prevState => !prevState)}
            toggleDownloadPermission={() => setHasDownloadPermission(prevState => !prevState)}
          />
        )}
        {isManualDialogOpen && (
          <ManualDialog
            onClose={() => {
              setIsManualDialogOpen(false)
              setTimeout(() => setIsChangeModeTipShown(true), INITIAL_SCREEN_DELAY_IN_MS)
              window.localStorage.setItem(LOCAL_STORAGE.initial.wasManualDialogShown, true.toString())
          }} />
        )}
        {isChangelogDialogOpen && (
          <ChangelogDialog onAccept={() => setIsChangelogDialogOpen(false)} />
        )}
        {isCopyrightsDialogOpen && (
          <CopyrightsDialog onAccept={() => setIsCopyrightsDialogOpen(false)} />
        )}
        {isPrivacyPolicyDialogOpen && (
          <PrivacyPolicyDialog onAccept={() => setIsPrivacyPolicyDialogOpen(false)} />
        )}
        {isSupportDialogOpen && (
          <SupportDialog onAccept={() => setIsSupportDialogOpen(false)} />
        )}
        {isRandomMusicNotificationShown && (
          <Notification>{t(`view.player.random-notification-${isMusicRandomized}`)}</Notification>
        )}
        {isRepeatMusicNotificationShown && (
          <Notification>{t(`view.player.repeat-notification-${isMusicRepeated}`)}</Notification>
        )}
        {timeEventsNotification.length > 0 && (
          <Notification>{t(timeEventsNotification)}</Notification>
        )}
        {(areResourcesInitiallyCounted && songsDownloaded === 0 && !isOnline) && (
          <Dialog
            isShown
            textCenter
            title={t("dialog.offline.title")}
          >
            {t("dialog.offline.content")}
          </Dialog>
        )}
        <section className={styles.player}>
          <header className={styles.mode}>
            <Button
              onClick={handleMusicMode}
              type="PLAYER"
              title={t("view.mode.change")}
              disabled={isMusicModeChanging}
            ><img src={`${process.env.PUBLIC_URL}/assets/player/toggle.png`} alt="" /></Button>
            <h3 title={t("view.mode.current")}>
              {t(`view.mode.${currentMusicMode.toLowerCase()}`)}
              <img src={`/assets/player/${currentMusicMode.toLowerCase()}.png`} alt={t(`view.mode.${currentMusicMode.toLowerCase()}`)} />
            </h3>
            <SettingsDialogButton onClick={() => setIsSettingsDialogOpen(true)} />
          </header>
          <img src={currentSongCoverUri} alt={t("view.player.cover")} className={styles.cover} />
          <audio
            ref={audioRef}
            controls
            hidden
          />
          <div className={styles.content}>
            <h3 className={styles.title}>{t(currentSongNameTranslationKey)}</h3>
            <Button
              type="PLAYER"
              onClick={handleRandomMode}
              title={t(`view.player.random-alt-${isMusicRandomized}`)}
              className={styles.random}
              withStrikeIcon={!isMusicRandomized}
            >
              <img src={`${process.env.PUBLIC_URL}/assets/player/random.png`} alt="" />
            </Button>
            <Button
              type="PLAYER"
              onClick={handlePreviousSong}
              title={t("view.player.previous")}
              disabled={isMusicModeChanging}
            >
              <img src={`${process.env.PUBLIC_URL}/assets/player/previous.png`} alt="" />
            </Button>
            <Button
              className={styles.playPause}
              onClick={toggleSongPlayingState}
              disabled={isMusicModeChanging}
              type="PLAYER"
              title={t(`view.player.${isMusicPlaying ? "pause" : "play"}`)}
            ><img src={`/assets/player/${isMusicPlaying ? "pause" : "play"}.png`} alt="" /></Button>
            <Button
              type="PLAYER"
              onClick={handleNextSong}
              title={t("view.player.next")}
              disabled={isMusicModeChanging}
            >
              <img src={`${process.env.PUBLIC_URL}/assets/player/next.png`} alt="" />
            </Button>
            <Button
              type="PLAYER"
              onClick={handleRepeatMode}
              withStrikeIcon={!isMusicRepeated}
              title={t(`view.player.repeat-alt-${isMusicRepeated}`)}
            >
              <img src={`${process.env.PUBLIC_URL}/assets/player/repeat.png`} alt="" />
            </Button>
          </div>
        </section>
      </section>
    </>
  )
}

export default Player
