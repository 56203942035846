import { useTranslation } from "react-i18next"
import styles from "./AuthorsContent.module.css"

function AuthorsContent() {
    const { t } = useTranslation()
    return (
        <section className={styles.root}>
            <h3>{t("content.authors.title")}</h3>
            <p>{t("content.authors.content-1")} <a href="mailto:email@homm3musicplayer.com">email@homm3musicplayer.com</a>{t("content.authors.content-2")}</p>
        </section>
    )
}

export default AuthorsContent