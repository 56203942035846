import { createContext } from "react";

export enum TimeEvent {
    CHRISTMAS = 'CHRISTMAS'
}

export interface TimeEventContextKind {
    current: TimeEvent | null
    isEnabled: boolean
    setTimeEvent: (nextTimeEvent: TimeEvent | null ) => unknown
    setIsEnabled: (nextIsEnabled: boolean) => unknown
}

const defaultValue: TimeEventContextKind = {
    current: null,
    isEnabled: true,
    setTimeEvent: (nextTimeEvent: TimeEvent | null) => {},
    setIsEnabled: (nextIsEnabled: boolean) => {},
}

export const TimeEventContext = createContext<TimeEventContextKind>(defaultValue);
