import { useTranslation } from "react-i18next"
import { SupportContent } from "components/content"
import Dialog from "../Dialog"

interface SupportDialogProps {
    onAccept: () => unknown
}

function SupportDialog({ onAccept }: SupportDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            textCenter
            title={t("content.support.title")}
            onAccept={onAccept}
            onExit={onAccept}
        >
            <SupportContent />
        </Dialog>
    )
}

export default SupportDialog